import React from 'react';
import { TabList } from 'tyb';
import { TabModified } from 'ucode-components';
import { withRouter } from "react-router";
import queryString from 'query-string';

class ActivityTabList extends React.Component {
  componentDidMount() {
  }

  handleOnChange = (val) => {
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    parsed.step = val;
    const path = location.pathname;
    history.replace(`${path}?${queryString.stringify(parsed)}`);
  }

  render() {
    const { list, value } = this.props;
    return (
      <TabModified>
        <TabList
          list={list}
          value={value}
          onChange={(val) => this.handleOnChange(val)}
        />
      </TabModified>
    );
  }
}

export default withRouter(ActivityTabList);
