import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
import ActivityTabList from '../../../ActivityManagement/ActivityManagement/Activity/ActivityTabList';

// import { TabList } from 'tyb';
// import { TabModified } from 'ucode-components';


const { loadCodePrintingConfigById } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
// import ActivityTabList from '../../ActivityTabList';
import Step0 from './step0';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

import './index.scss';

@connect(
  state => ({
    currentStep: state.codePrintingConfig.currentStep,
    loadCodePrintingConfigByIdLoading: state.codePrintingConfig.loadCodePrintingConfigByIdLoading,
  }),
  {
    setNavBar,
    loadCodePrintingConfigById: loadCodePrintingConfigById.REQUEST
  }
)
class EditCodePrintingConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: ['组合印刷规格', '填写信息配置', '建立审核流程', '设置预警参数']
    }
  }

  componentDidMount() {
    this.props.setNavBar('编辑组合印刷规格');

    const parsed = queryString.parse(this.props.location.search);
    const { id } = parsed;

    this.props.loadCodePrintingConfigById(id)
  }


  getTabList = (promotionType) => {
    console.log(promotionType)
    switch (promotionType) {
      case 'QR_CODE':
      case 'INTERACT':
      case 'INVITE':
      case 'CHECK_IN':
      default:
        return [
          {
            text: '印刷类型',
            value: 0,
          }, {
            text: '信息配置',
            value: 1,
          }, {
            text: '审核流程',
            value: 2,
          }, {
            text: '预警参数',
            value: 3
          }
        ];
    }
  }


  render() {
    // const { currentStep } = this.props;
    // const { steps } = this.state;
    const parsed = queryString.parse(this.props.location.search);
    // const { promotionType, promotionStatus, promotionId, historyId, action, step } = parsed;
    const { step } = parsed;
    return (
      <div className="">

        {/* <TabModified>
          <TabList
            list={this.getTabList('QR_CODE')}
            value={parseFloat(step)}
          // onChange={(val) => this.handleOnChange(val)}
          />
        </TabModified> */}

        {/* <ActivityTabList
          list={this.getTabList('QR_CODE')}
          value={parseFloat(step)}
        /> */}
        {/* <div> */}
        {(step === '0' && !this.props.loadCodePrintingConfigByIdLoading) &&
          <Step0 zuhe={true} />
        }

        {(step === '1' && !this.props.loadCodePrintingConfigByIdLoading) &&
          <Step1 />
        }

        {(step === '2' && !this.props.loadCodePrintingConfigByIdLoading) &&
          <Step2 />
        }

        {(step === '3' && !this.props.loadCodePrintingConfigByIdLoading) &&
          <Step3 />
        }
        {/* </div> */}
      </div>
    )
  }
}

export default EditCodePrintingConfig;