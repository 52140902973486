import React from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { Button, Input, FormGroupList } from 'tyb';
import { TablePopConfirm } from 'ucode-components';
import { NewSelect  } from 'ucode-components';
import { FormGroupField } from 'tyb';
const { setCurrentStep, setEditStep, editConfig, loadSelect } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { domainConfigsByCodeSourceType } = iceStarkData.store.get('commonAction')?.domainConfigs;
const { loadSelectList } = iceStarkData.store.get('commonAction')?.codeSource;
const { authorizerConfigs } = iceStarkData.store.get('commonAction')?.wechat;
const { tagstatusSelections } = iceStarkData.store.get('commonAction')?.links;
import * as validation from './validation';
import enums from '@/assets/enum.json';

import queryString from 'query-string';
import { TabList } from 'tyb';
import { TabModified } from 'ucode-components';

const setting = (props) => {

  if (props.showValue.combinationTagType == 'SAME_LEVEL_TAG') {
    return (
      <div>

        {props.showValue.combinationConfigDtos.map((item, index) => {
          return <p key={index} style={{ fontSize: '12px', marginBottom: 0 }}>第{index + 1}个码规格-{props.showValue.selector.filter(key => key.id == item.eseConfigId)[0] ? props.showValue.selector.filter(key => key.id == item.eseConfigId)[0].enterpriseDomain.name : ""}</p>
        })}
      </div>
    )
  }
  else {
    return (
      <div>
        {props.showValue.combinationConfigDtos.map((item, index) => {
          return <p key={index} style={{ fontSize: '12px', marginBottom: 0 }}> {index == 0 ? '箱码-' : props.showValue.combinationConfigDtos.length == 2 ? '瓶规格-' : `第${index}个码规格-`}{props.showValue.selector.filter(key => key.id == item.eseConfigId)[0] ? props.showValue.selector.filter(key => key.id == item.eseConfigId)[0].enterpriseDomain.name : ""}</p>
        })}
      </div>
    )
  }


}

class CreateCodePrintingConfigStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordComplexitySelect: Object.keys(enums.passwordComplexity).map(item => { return { value: item, text: enums.passwordComplexity[item].name } }),
      printFileFormatSelect: [
        {
          text: 'CSV',
          value: 'CSV',
        },
        {
          text: 'TXT',
          value: 'TXT',
        },
      ],
      registrationMethodSelect: [
        {
          text: '线上录入',
          value: 'ONLINE_INPUT',
        },
        {
          text: '线下采集',
          value: 'OFFLINE_ACQUISITION',
        },
      ],
    }
  }


  getTabList = (promotionType) => {
    switch (promotionType) {
      case 'QR_CODE':
      case 'INTERACT':
      case 'INVITE':
      case 'CHECK_IN':
      default:
        return [
          {
            text: '印刷类型',
            value: 0,
          }, {
            text: '信息配置',
            value: 1,
          }, {
            text: '审核流程',
            value: 2,
          }, {
            text: '预警参数',
            value: 3
          }
        ];
    }
  }

  handleOnChange = (val) => (values) => {
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    parsed.step = val;
    const path = location.pathname;
    history.replace(`${path}?${queryString.stringify(parsed)}`);


    this.props.setEditStep({ step: 1, values });
  }



  componentDidMount() {
    const { loadSelectList, authorizerConfigs, domainConfigsByEseId, codePrintingConfigById: { name, code, codeSourceId, waAppId, wwAppId, eseDomainId, jumpPath = '/pages/scan/scan', passwordComplexity, printFileFormat, registrationMethod, enterpriseDomain, tagInitStatus } } = this.props;
    // console.log(passwordComplexity)
    // console.log(passwordComplexitySelect)
    // console.log()
    // this.props.tagstatusSelections({ data: {}, status: 'ENABLE' });
    if (passwordComplexity == "HIGHLY_SECRECY" || passwordComplexity == "GENERAL_SECRECY") {
      this.setState({
        passwordComplexitySelect: [
          { value: "GENERAL_SECRECY", text: "一般保密度8位纯数字" },
          { value: "HIGHLY_SECRECY", text: "中保密度36位字母数字" }
        ]
      })

    } else if (passwordComplexity == "IPC_SECRECY") {
      this.setState({
        passwordComplexitySelect: [{ value: "IPC_SECRECY", text: "高保密度工厂授权证书" }]
      })

    }

    setTimeout(() => {

      if (enterpriseDomain) {
        domainConfigsByEseId({ codeSourceType: enterpriseDomain.codeSourceType, status: '' })

        this.props.loadSelect();
        loadSelectList(enterpriseDomain.codeSourceType);
        if (enterpriseDomain.codeSourceType === 'WECHART_CODE') {
          authorizerConfigs('MINI_PROGRAM');
          authorizerConfigs('WECHAT_SCRIPTION');

        }
      }
    }, 1000);


    if (this.props.editStep1) {
      this.props.editStep1.tagInitStatus = this.props.editStep1.tagInitStatus ? this.props.editStep1.tagInitStatus : 'defalut'
      this.props.initialize(this.props.editStep1)
    }
    else {
      // let passwordComple=(passwordComplexity=="IPC_SECRECY")?"IPC":"COMMON";
      const params = {
        name,
        eseDomainId,
        passwordComplexity,
        waAppId,
        wwAppId,
        jumpPath,
        printFileFormat,
        registrationMethod,
        codeSourceId,
        code,
        tagInitStatus: tagInitStatus ? tagInitStatus : 'defalut'
      }
      // console.log(params)
      this.props.initialize(params)
    }

  }


  perStep = () => {
    this.props.setCurrentStep(0);
  }

  next = (values) => {
    //  debugger
    const { codePrintingConfigById, editStep2, editStep3 } = this.props;
    const params = {
      combinationTagType: 'SAME_LEVEL_TAG',
      ipcNoDownloadWntime: 2,
      ...codePrintingConfigById,
      ...editStep2,
      ...editStep3,
      ...values,
      passwordComplexity: values.passwordComplexity,
      configWorkflowTemplateDTOs: editStep2 ? JSON.parse(JSON.stringify(editStep2.configWorkflowTemplateDTOs)) : codePrintingConfigById.wfTemplateList,

    }

    params.configWorkflowTemplateDTOs.forEach(v => {
      if (!v.id) {
        v.factoryId = v.factoryId.value
        v.workflowNodes.forEach(v2 => {
          v2.signerCode = v2.signerCode.value
        })
      }
      if (v.workflowNodes.length > 0) {
        let flag = false
        v.workflowNodes.map(it1 => {
          if ((it1.signerCode == -1 || it1.signerCode.value == -1) && it1.nodeType == 'PROCESS_START') {
            flag = true
          }
        })
        if (flag) {
          v.workflowNodes = []
        } else {
          if (v.workflowNodes[1].signerCode == '-1') {
            v.workflowNodes.splice(1, 1);
          }
        }
      }
    });
    // console.log(params,"paramsparamsparamsparams")
    params.multiCodeAsc == '-1' ? params.multiCodeAsc = false : '';

    if (params.tagInitStatus == 'default') { params.tagInitStatus = '' }
    this.props.editConfig({ params, history: this.props.history });
  }

  canCancel = () => {
    this.setState({
      confirmStatus: true
    });
  }

  cancel = () => {
    this.props.history.goBack();
  }

  requiredByInput = validation.required('请填写')

  requiredBySelect = validation.required('请选择')

  maxLength = validation.maxLength(30)


  render() {
    const { domainConfigsByEseIdSelect, codeSourceSelectList, handleSubmit, change, permissionIds, codePrintingConfigById, miniSelections, wechatSelections, tagstatusSelection } = this.props;
    const { passwordComplexitySelect, printFileFormatSelect, registrationMethodSelect } = this.state;

    const parsed = queryString.parse(this.props.location.search);
    const { step } = parsed;


    return (
      <div>
        <TabModified>
          <TabList
            list={this.getTabList('QR_CODE')}
            value={parseFloat(step)}
            onChange={(val) => handleSubmit(this.handleOnChange(val))()}
          />
          {/* onClick={handleSubmit(this.next) */}
        </TabModified>

        <div className="EditCodePrintingConfig">
          <div className="step1">
            <FormGroupList>
              <FormGroupField
                name="name"
                required
                label="码印刷规格名称"
                component={Input}
                validate={[this.requiredByInput, this.maxLength]}


              >
                <div className="extraText"> 单个企业下名称唯一，支持中文、英文字母、中划线和下划线，长度不超过30个字符</div>
              </FormGroupField>
              <FormGroupField
                name="code"
                label="码印刷规格编码"
                component={Input}
                validate={[this.maxLength]}
              >
              </FormGroupField>

              {/* // {JSON.stringify(domainConfigsByEseIdSelect)}
// {this.props.codePrintingConfigById.eseDomainId} */}
              {/* <FormGroupField
                name="eseDomainId"
                label="码域名"
                component={NewSelect}
                disabled={true}
                placeholder={'请选择'}
                list={domainConfigsByEseIdSelect}
                validate={[this.requiredBySelect]}
              /> */}
              {/* {codePrintingConfigById.enterpriseDomain ? (codePrintingConfigById.enterpriseDomain.codeSourceType !== 'PLATFORM_STANDARD' && */}
              {codePrintingConfigById.enterpriseDomain ? (codePrintingConfigById.enterpriseDomain.codeSourceType !== 'PLATFORM_STANDARD' && codePrintingConfigById.enterpriseDomain.codeSourceType !== 'WECHART_CODE' &&
                <FormGroupField
                  name="codeSourceId"
                  required
                  label="码源"
                  style={{ width: 330 }}
                  component={NewSelect}
                  placeholder={'请选择'}
                  list={codeSourceSelectList}
                  // validate={[this.requiredBySelect]}
                  validate={[this.requiredBySelect]}
                  disabled={true}
                />) : ''
              }

              {codePrintingConfigById.enterpriseDomain ? (codePrintingConfigById.enterpriseDomain.codeSourceType !== 'WECHART_CODE' && codePrintingConfigById.combinationTagType == 'SINGLE_TAG' &&
                <FormGroupField
                  name="eseDomainId"
                  required
                  label="码域名"
                  component={NewSelect}
                  style={{ width: 330 }}
                  placeholder={'请选择'}
                  list={domainConfigsByEseIdSelect}

                  // validate={[this.requiredBySelect]}
                  validate={[this.requiredBySelect]}
                  disabled={true}
                />) : ''
              }
              {codePrintingConfigById.combinationTagType && codePrintingConfigById.combinationTagType !== 'SINGLE_TAG' &&
                <FormGroupField
                  name="eseDomainId"
                  label="码域名"
                  component={setting}
                  showValue={{ ...codePrintingConfigById, selector: this.props.selector }}
                // style={{ width: 190 }}
                />
              }



              {codePrintingConfigById.enterpriseDomain && codePrintingConfigById.enterpriseDomain.codeSourceType === 'WECHART_CODE' && codePrintingConfigById.combinationTagType === 'SINGLE_TAG' &&
                <React.Fragment>
                  <FormGroupField
                    className="wechat-config"
                    name="waAppId"
                    required
                    label="打开小程序"
                    component={NewSelect}
                    style={{ width: 330 }}
                    placeholder={'请选择'}
                    list={miniSelections}
                    // disabled={true}
                    validate={[this.requiredBySelect]}
                  >
                    <a href="javascript:;" className="more" onClick={() => { this.setState({ showJump: true }) }}>更多</a>
                  </FormGroupField>
                  {this.state.showJump && <FormGroupField
                    name="jumpPath"
                    label="扫码跳转路径"
                    component={Input}
                    validate={[this.requiredByInput]}
                  />}
                </React.Fragment>
              }

              {codePrintingConfigById.enterpriseDomain ? (codePrintingConfigById.enterpriseDomain.codeSourceType === 'WECHART_CODE' && codePrintingConfigById.combinationTagType === 'SINGLE_TAG' &&
                <FormGroupField
                  name="wwAppId"
                  required
                  label="发码公众号"
                  style={{ width: 330 }}
                  component={NewSelect}
                  placeholder={'请选择'}
                  list={wechatSelections}
                  // validate={[this.requiredBySelect]}
                  validate={[this.requiredBySelect]}
                  disabled={true}
                />) : ''
              }




              <FormGroupField
                name="passwordComplexity"
                required
                label="码包解压密码"
                style={{ width: 330 }}
                component={NewSelect}
                placeholder={'请选择'}
                list={passwordComplexitySelect}
                validate={[this.requiredBySelect]}

              />

              <FormGroupField
                name="printFileFormat"
                required
                label="码包中的文件格式"
                style={{ width: 330 }}
                component={NewSelect}
                placeholder={'请选择'}
                list={printFileFormatSelect}
                validate={[this.requiredBySelect]}

              />

              <FormGroupField
                name="registrationMethod"
                required
                label="码信息录入方式"
                style={{ width: 330 }}
                component={NewSelect}
                disabled={true}
                placeholder={'请选择'}
                list={registrationMethodSelect}
                validate={[this.requiredBySelect]}

              />
              {/* 迎驾贡注释 */}
              {/* <FormGroupField
                name="tagInitStatus"
                label='码初始环节状态'
                placeholder="请选择"
                component={NewSelect}
                list={tagstatusSelection}
              /> */}
            </FormGroupList>

            <div className="line" ></div>
            <div className="button-block">
              <Button className="weak-button" onClick={this.canCancel} >取消</Button>
              {/* <Button className="weak-button button-margin" onClick={this.perStep} >上一步</Button>  */}
              {permissionIds.includes('code.tagConfig.edit') && <Button className="button-margin" onClick={handleSubmit(this.next)} >确定</Button>}
              {(this.state.confirmStatus) && <TablePopConfirm
                strongText={'印刷规格暂未完成，确认离开吗？'}
                text={'离开后编辑内容不做保存，请谨慎操作'}
                styles={{ marginTop: -165 }}   // height: 154,
                button={['确定', '取消']}
                onOk={this.cancel}
                onCancel={() => { this.setState({ confirmStatus: false }) }}
              />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let CreateCodePrintingConfigStep1Form = reduxForm({
  form: 'CreateCodePrintingConfigStep1Form', // a unique identifier for this form
  // enableReinitialize: true,
})(CreateCodePrintingConfigStep1)

CreateCodePrintingConfigStep1Form = connect(
  state => ({

    editStep1: state.codePrintingConfig.editStep1,
    editStep2: state.codePrintingConfig.editStep2,
    editStep3: state.codePrintingConfig.editStep3,
    domainConfigsByEseIdSelect: state.domainConfigs.domainConfigsByCodeSourceTypeSelect,
    codeSourceSelectList: state.codeSource.selectList,
    codePrintingConfigById: state.codePrintingConfig.codePrintingConfigById,
    permissionIds: state.users.permissionIds || [],
    miniSelections: state.wechat.authorizerConfigsSelector,
    wechatSelections: state.wechat.wechatScriptionSelector,
    selector: state.codePrintingConfig.selector,
    tagstatusSelection: state.links.tagstatusSelections1 || [],
  }),
  {
    //码状态
    tagstatusSelections: tagstatusSelections.REQUEST,
    setCurrentStep,
    domainConfigsByEseId: domainConfigsByCodeSourceType.REQUEST,
    setEditStep,
    loadSelect: loadSelect.REQUEST,
    loadSelectList: loadSelectList.REQUEST,
    editConfig: editConfig.REQUEST,
    authorizerConfigs: authorizerConfigs.REQUEST,
  }
)(CreateCodePrintingConfigStep1Form)

CreateCodePrintingConfigStep1Form = withRouter(CreateCodePrintingConfigStep1Form);

export default CreateCodePrintingConfigStep1Form;