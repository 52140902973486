import React from 'react';
import { reduxForm, FieldArray } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import {
  RadioButtonGroup, RadioGroup, Button, Input, FormGroupList, Tip
} from 'tyb';
import { TablePopConfirm } from 'ucode-components';
import { NewSelect  } from 'ucode-components';
import { FormGroupField } from 'tyb';
const { setCurrentStep, setEditStep, editConfig } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { loadAddressBookSelections } = iceStarkData.store.get('commonAction')?.addressBooks;
const { loadSelections } = iceStarkData.store.get('commonAction')?.factory;
const { loadUsersSelections } = iceStarkData.store.get('commonAction')?.users;
const { loadIpcSelections } = iceStarkData.store.get('commonAction')?.certificate;
const { domainConfigsByEseId } = iceStarkData.store.get('commonAction')?.domainConfigs;
import * as validation from './validation';


import queryString from 'query-string';
import { TabList } from 'tyb';
import { TabModified } from 'ucode-components';
import { number } from 'prop-types';




const requiredByInput = validation.required('请填写')

const requiredBySelect = validation.required('请选择')
const workflow = (value, values, props, name) => {
  let list = values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] ? values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]].ipcId : ''

  if (list && !value) {
  }
  else if (!value) {
    return '码包接收人与码包拉取设备，至少选其一'
  }

}
const workflowIcp = (value, values, props, name) => {
  // 2023-08-25 迎驾贡注释
  // let list = values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] ? values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]].workflowNodes[2] : ''
  // if (list && !value) {
  // }
  // else if (!value) {
  //   return '码包接收人与码包拉取设备，至少选其一'
  // }

  if (!value) {
    return '码包接收人与码包拉取设备，至少选其一'
  }


}

// 2023-10-10 字符长度限制改30为100
const maxLength = validation.maxLength(100)

const renderAudits = ({ fields, usersSelectionsOne, usersSelectionsTwo, addressBookSelections, factorySelector, change, ipcSelectionsList, initialValues, handleFactoryChange }) => {

  if (fields.getAll()) {
    fields.getAll().map((item) => {
      if (!item.factoryId) {
        ipcSelectionsList = []
      }
      for (var i = 0; i < item.workflowNodes.length; i++) {
        let arr = [];
        const dealFun = (type) => {
          if (item.workflowNodes.findIndex((v) => v.nodeType == type) > -1) {
            arr.push(item.workflowNodes[item.workflowNodes.findIndex((v) => v.nodeType == type)]);
          } else {
            arr.push({ signerCode: "", nodeType: type });
          }
        };
        ["PROCESS_START", "PROCESS_AUDIT", "PACKET_RECEIVER", "PASSWORD_RECEIVER"].map((typeV) => { dealFun(typeV); });
        // console.log(arr, 'arr')
        let arr1 = arr
        arr.filter((it, index) => { if (it.signerCode == "-1" && it.nodeType == "PROCESS_START" && it.nodeType == "USER") { return arr1[index].signerCode = { text: "ISV账号", value: -1, disabled: '' } } })
        item.workflowNodes = arr1;

        for (var i = 0; i < item.workflowNodes.length; i++) {
          if (item.workflowNodes[i].signerCode == "-1" || item.workflowNodes[i].signerCode == "") {
            item.workflowNodes[i].signerCode = ""
          }
          if (item.workflowNodes[1].signerCode == "" || item.workflowNodes[i].signerCode == "-1") {
            item.workflowNodes[1].signerCode = "-1"
          }
        }
        // console.log(item.workflowNodes, 'item.workflowNodes')
      }
    });
  }

  //    })
  // }


  return <div>
    {
      fields.map((audit, index) => (
        fields.get(index).deleted != 'YES' && <div key={index} style={fields.get(index).status == 'DISABLE' ? { backgroundColor: '#eee' } : {}}>
          <div className="Audit">
            {(fields.get(index).id && fields.get(index).status == 'ENABLE') && <div className="Audit-delete" onClick={() => {
              change(`configWorkflowTemplateDTOs[${index}].status`, 'DISABLE')
            }}>禁用</div>}
            {(fields.get(index).id && fields.get(index).status == 'DISABLE') && <div className="Audit-delete" onClick={() => {
              change(`configWorkflowTemplateDTOs[${index}].status`, 'ENABLE')
            }}>启用</div>}
            {!fields.get(index).id && <div className="Audit-delete" onClick={() => {
              // if (fields.get(index).id) {
              //   change(`configWorkflowTemplateDTOs[${index}].deleted`, 'YES')
              // }
              // else {
              fields.remove(index);
              // }
            }}>删除</div>}
            <FormGroupList>
              <FormGroupField
                name={`${audit}.name`}
                label="审核流程名称"
                disabled={fields.get(index).status == 'DISABLE' ? true : false}
                component={Input}
                validate={[requiredByInput, maxLength]}
              >
                <div className="extraText">支持中文、英文字母、中划线和下划线，长度不超过100个字符</div>

              </FormGroupField>
            </FormGroupList>

            <div>
              {fields.get(index).id && <FormGroupList>
                <span style={{ width: '90px', display: 'inline-block' }}>审核流ID</span>
                <span className="extraText">{fields.get(index).id}</span>
              </FormGroupList>}
              <div style={{ display: 'inline-table' }}>
                <FormGroupField
                  className="specClass"
                  style={{ minWidth: '110px', width: '110px', marginLeft: '11px' }}
                  name={`${audit}.workflowNodes[0].signerCode`}
                  label="码包申请人"
                  disabled={fields.get(index).id ? true : false}
                  component={NewSelect}
                  needText={fields.get(index).id ? fields.get(index).workflowNodes[0].signerCode.value == '-1' ? true : false : true}

                  placeholder={'请选择'}
                  list={usersSelectionsOne}
                  validate={[requiredBySelect]}
                  onChange={(val) => { if ((fields.get(index).id && val == -1) || val.value == -1) { change(`configWorkflowTemplateDTOs[${index}].workflowNodes[1].signerCode`, ''); change(`configWorkflowTemplateDTOs[${index}].workflowNodes[2].signerCode`, ''); if (initialValues != "IPC_SECRECY") { change(`configWorkflowTemplateDTOs[${index}].workflowNodes[3].signerCode`, '') } } }}
                />
              </div>
              <div style={{ display: 'inline-table' }}>
                {!(fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1) && <FormGroupField
                  className="specClass2"
                  style={{ minWidth: '110px', width: '110px' }}
                  name={`${audit}.workflowNodes[1].signerCode`}
                  label="码包审核人"
                  disabled={fields.get(index).id ? true : false}
                  component={NewSelect}
                  needText={fields.get(index).id ? false : true}

                  placeholder={'请选择'}
                  list={usersSelectionsTwo}
                  validate={[requiredBySelect]}
                />}
                {fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1 &&
                  <FormGroupList>
                    <span>码包审核人</span>
                    <span> : </span>
                    <span className="extraText" style={{ paddingRight: '15px' }}>无（免审）</span>
                  </FormGroupList>}
              </div>
              {/* {JSON.stringify(fields.get(index).workflowNodes)} */}
              <div style={{ display: 'inline-table' }}>
                {!(fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1) && <FormGroupField
                  className="specClass2"
                  style={{ minWidth: '110px', width: '110px' }}
                  name={`${audit}.workflowNodes[2].signerCode`}
                  label="码包接收人"
                  disabled={fields.get(index).id ? true : false}
                  component={NewSelect}
                  needText={fields.get(index).id ? false : true}

                  placeholder={'请选择'}
                  list={addressBookSelections}
                  validate={initialValues == "IPC_SECRECY" ? workflow : [requiredBySelect]}
                />}
                {fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1 &&
                  <FormGroupList>
                    <span>码包接收人</span>
                    <span> : </span>
                    <span className="extraText" style={{ paddingRight: '15px' }}>ISV账号</span>
                  </FormGroupList>}
              </div>
              {initialValues != "IPC_SECRECY" && <div style={{ display: 'inline-table' }}>
                {!(fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1) && <FormGroupField
                  className="specClass2"
                  style={{ minWidth: '110px', width: '110px' }}
                  name={`${audit}.workflowNodes[3].signerCode`}
                  label="码包密码获取人"
                  disabled={fields.get(index).id ? true : false}
                  component={NewSelect}
                  needText={fields.get(index).id ? false : true}

                  placeholder={'请选择'}
                  list={addressBookSelections}
                  validate={[requiredBySelect]}
                />}
                {fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1 &&
                  <FormGroupList>
                    <span>码包解压密码获取人</span>
                    <span> : </span>
                    <span className="extraText">ISV账号</span>
                  </FormGroupList>}
              </div>}
            </div>

            <FormGroupList>
              <FormGroupField
                name={`${audit}.factoryId`}
                style={{ width: 330 }}
                label="码包接收工厂"
                placeholder={'请选择'}
                disabled={fields.get(index).id ? true : false}
                component={NewSelect}
                needText={fields.get(index).id ? false : true}

                list={fields.get(index).id ? [{ value: fields.get(index).factoryId, text: fields.get(index).factoryName }] : factorySelector}
                validate={[requiredBySelect]}
                onChange={(val) => { handleFactoryChange(val); change(`${audit}.ipcId`, '') }}
              />
            </FormGroupList>
            <FormGroupList>
              {initialValues == "IPC_SECRECY" && <div style={{ display: 'inline-table' }}>
                <FormGroupField
                  style={{ width: 330 }}
                  name={`${audit}.ipcId`}
                  label="码包拉取设备"
                  disabled={fields.get(index).id ? true : false}
                  component={NewSelect}
                  needText={fields.get(index).id ? false : true}

                  placeholder={'请选择'}
                  list={fields.get(index).id ? [{ value: fields.get(index).ipcId, text: fields.get(index).ipcName }] : ipcSelectionsList || []}
                  validate={workflowIcp}
                />
              </div>}
            </FormGroupList>
            {initialValues == "IPC_SECRECY" && <FormGroupList>
              <span>码包解压密码获取人</span>
              <span> : </span>
              <span className="extraText">由安装证书的软件自行解码无需接收人</span>
            </FormGroupList>}

          </div>
          <div className="line" ></div>
        </div>
      ))
    }
    {/* 2023-10-10 长度限制改20为100 */}
    {fields.getAll() ? fields.getAll().filter(key => key.deleted != 'YES').length < 100 ? <div className="addBlock" onClick={() => {
      // ipcSelectionsList=[];
      // console.log(ipcSelectionsList)
      // console.log(fields)
      fields.push(
        {

          workflowNodes: [
            {
              nodeCategory: 'USER',
              nodeType: 'PROCESS_START',
            },
            {
              nodeCategory: 'USER',
              nodeType: 'PROCESS_AUDIT',
            },

            {
              nodeCategory: 'USER',
              nodeType: 'PACKET_RECEIVER',
            },
            {
              nodeCategory: 'USER',
              nodeType: 'PASSWORD_RECEIVER',
            },
          ]
        }
      )

    }}>添加审核流程（最多100个）</div> : '' : ''}

  </div>
}

class CreateCodePrintingConfigStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  getTabList = (promotionType) => {
    switch (promotionType) {
      case 'QR_CODE':
      case 'INTERACT':
      case 'INVITE':
      case 'CHECK_IN':
      default:
        return [
          {
            text: '印刷类型',
            value: 0,
          }, {
            text: '信息配置',
            value: 1,
          }, {
            text: '审核流程',
            value: 2,
          }, {
            text: '预警参数',
            value: 3
          }
        ];
    }
  }

  handleOnChange = (val) => (values) => {
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    parsed.step = val;
    const path = location.pathname;
    history.replace(`${path}?${queryString.stringify(parsed)}`);

    this.props.setEditStep({ step: 2, values });
  }

  handleFactoryChange = (data) => {
    this.props.loadIpcSelections({ status: "ENABLE", factoryId: data.value })
  }
  cancel = () => {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.props.loadAddressBookSelections({ status: 'ENABLE' });
    this.props.loadFactorySelections({ factoryType: 'PRINT', status: 'ENABLE' });
    this.props.loadUsersSelections({ status: 'ENABLE' });
    this.props.loadIpcSelections({ status: "ENABLE", factoryId: "" })
    const { codePrintingConfigById: { wfTemplateList } } = this.props;

    this.setState({
      initialValues: this.props.codePrintingConfigById.passwordComplexity || ""
    })

    let newwfTemplateList = JSON.parse(JSON.stringify(wfTemplateList ? wfTemplateList : []));
    for (let i = 0; i < newwfTemplateList.length; i++) {
      if (newwfTemplateList[i].workflowNodes.length == 3) {
        newwfTemplateList[i].workflowNodes.splice(1, 0, { signerCode: '-1' })
      }
      if (newwfTemplateList[i].workflowNodes.length == 0) {
        newwfTemplateList[i].workflowNodes = [{ signerCode: { text: "ISV账号", value: -1 }, nodeCategory: "USER", nodeType: "PROCESS_START" }, { signerCode: "", nodeCategory: "USER", nodeType: "PROCESS_AUDIT" }, { signerCode: "", nodeCategory: "USER", nodeType: "PACKET_RECEIVER" }, { signerCode: "", nodeCategory: "USER", nodeType: "PASSWORD_RECEIVER" }]
      }
    }

    if (this.props.editStep2) {
      this.props.initialize(this.props.editStep2)
    }
    else {
      // console.warn(newwfTemplateList,'newwfTemplateList');
      this.props.initialize({ configWorkflowTemplateDTOs: newwfTemplateList })
    }

  }

  perStep = () => {
    this.props.setCurrentStep(1);
  }

  next = (values) => {

    // console.log(values)
    const { codePrintingConfigById, editStep1, editStep3 } = this.props;


    const params = {
      combinationTagType: 'SINGLE_TAG',
      ipcNoDownloadWntime: 2,
      ...codePrintingConfigById,
      ...editStep1,
      ...editStep3,
      ...values,
      configWorkflowTemplateDTOs: JSON.parse(JSON.stringify(values.configWorkflowTemplateDTOs)),
    }

    params.configWorkflowTemplateDTOs.forEach(v => {

      if (!v.id) {
        // console.log(  v.ipcId)
        // console.log(v.ipcId.value)
        // return

        if (v.ipcId) {
          v.ipcId = (typeof v.ipcId) == "number" ? v.ipcId : v.ipcId.value;
        }
        v.factoryId = (typeof v.factoryId) == "number" ? v.factoryId : v.factoryId.value;

        v.workflowNodes.forEach(v2 => {
          // console.log(v2.signerCode)
          v2.signerCode = !v2.signerCode ? -1 : v2.signerCode.value;
        })
      }
      // console.log(v.workflowNodes)
      let flag = false
      v.workflowNodes.map(it1 => {
        if ((it1.signerCode == -1 || it1.signerCode.value == -1) && it1.nodeType == 'PROCESS_START') {
          flag = true
        }
      })
      if (flag) {
        v.workflowNodes = []
      } else {
        // if (v.workflowNodes[1].signerCode == '-1') {
        //   v.workflowNodes.splice(1, 1);
        // }
        if (v.workflowNodes[3].signerCode == undefined || !v.workflowNodes[3].signerCode) {
          v.workflowNodes.splice(3, 1);
        }
        if (v.workflowNodes[2].signerCode == undefined || !v.workflowNodes[2].signerCode) {
          v.workflowNodes.splice(2, 1);
        }
        if (v.workflowNodes[1].signerCode == '-1') {
          v.workflowNodes.splice(1, 1);
        }
      }
    });
    if (params.passwordComplexity == "COMMON") {
      params.passwordComplexity = "GENERAL_SECRECY"
    }
    params.multiCodeAsc == '-1' ? params.multiCodeAsc = false : '';
    this.props.editConfig({ params, history: this.props.history });
  }
  canCancel = () => {
    this.setState({
      confirmStatus: true,
      initialValues: ""

    });
  }

  cancel = () => {
    this.props.history.goBack();
  }
  render() {

    const { handleSubmit, change, addressBookSelections = [], factorySelector, usersSelectionsOne, usersSelectionsTwo, form2, permissionIds, codePrintingConfigById, ipcSelectionsList } = this.props;

    const { initialValues } = this.state;
    // console.log(initialValues)
    const parsed = queryString.parse(this.props.location.search);
    const { step } = parsed;
    return (
      <div>
        <TabModified>
          <TabList
            list={this.getTabList('QR_CODE')}
            value={parseFloat(step)}
            onChange={(val) => handleSubmit(this.handleOnChange(val))()}
          />

        </TabModified>
        {form2 && form2.values && form2.values.configWorkflowTemplateDTOs &&
          form2.values.configWorkflowTemplateDTOs.filter(key => key.deleted != 'YES').length === 20 && <Tip style={{ marginTop: 20 }} hasClose={false} type="warning">
            一个码印刷配置最多可添加20个审核流程！
                    </Tip>}


        <div className="EditCodePrintingConfig">
          <div className="step2">

            {codePrintingConfigById.enterpriseDomain ? codePrintingConfigById.enterpriseDomain.codeSourceType !== 'CODE_IMPORT' ?
              <FieldArray name="configWorkflowTemplateDTOs" change={change} usersSelectionsOne={usersSelectionsOne} usersSelectionsTwo={usersSelectionsTwo} factorySelector={factorySelector} addressBookSelections={addressBookSelections} initialValues={initialValues} handleFactoryChange={this.handleFactoryChange} ipcSelectionsList={ipcSelectionsList} component={renderAudits} /> :
              <div style={{ textAlign: 'center' }}>
                您使用的码源无需建立审核流程
        </div> : ''
            }
            <div className="line" ></div>
            <div className="button-block" >
              <Button className="weak-button" onClick={this.canCancel} >取消</Button>
              {/* <Button className="weak-button button-margin" onClick={this.perStep} >上一步</Button>   */}
              {permissionIds.includes('code.tagConfig.edit') && <Button className="button-margin" onClick={handleSubmit(this.next)} >确定</Button>}
              {(this.state.confirmStatus) && <TablePopConfirm
                strongText={'印刷规格暂未完成，确认离开吗？'}
                text={'离开后编辑内容不做保存，请谨慎操作'}
                styles={{ marginTop: -165 }}   // height: 154,
                button={['确定', '取消']}
                onOk={this.cancel}
                onCancel={() => { this.setState({ confirmStatus: false }) }}
              />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let CreateCodePrintingConfigStep2Form = reduxForm({
  form: 'CreateCodePrintingConfigStep2Form', // a unique identifier for this form
  // enableReinitialize: true,
})(CreateCodePrintingConfigStep2)

CreateCodePrintingConfigStep2Form = connect(
  state => ({
    // initialValues: state.codePrintingConfig.createStep2,
    form2: state.form.CreateCodePrintingConfigStep2Form,
    editStep1: state.codePrintingConfig.editStep1,
    editStep2: state.codePrintingConfig.editStep2,
    editStep3: state.codePrintingConfig.editStep3,
    addressBookSelections: state.addressBooks.addressBookSelections,
    factorySelector: state.factory.selection,
    usersSelectionsOne: state.users.usersSelectionsOne,
    usersSelectionsTwo: state.users.usersSelectionsTwo,
    codePrintingConfigById: state.codePrintingConfig.codePrintingConfigById,
    permissionIds: state.users.permissionIds || [],
    ipcSelectionsList: state.certificate.ipcSelectionsList
  }),
  {
    setCurrentStep,
    setEditStep,
    editConfig: editConfig.REQUEST,
    loadAddressBookSelections: loadAddressBookSelections.REQUEST,
    loadFactorySelections: loadSelections.REQUEST,
    loadUsersSelections: loadUsersSelections.REQUEST,
    loadIpcSelections: loadIpcSelections.REQUEST,

  }
)(CreateCodePrintingConfigStep2Form)

CreateCodePrintingConfigStep2Form = withRouter(CreateCodePrintingConfigStep2Form);

export default CreateCodePrintingConfigStep2Form;
