import React from 'react';
import { reduxForm, FieldArray } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import {
  RadioButtonGroup, RadioGroup, Button, Input, FormGroupList
} from 'tyb';
import { TablePopConfirm } from 'ucode-components';

import { FormGroupField } from 'tyb';
const { setCurrentStep, editConfig, setEditStep } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { loadAddressBookSelections } = iceStarkData.store.get('commonAction')?.addressBooks;
const { loadSelections } = iceStarkData.store.get('commonAction')?.factory;
const { domainConfigsByEseId } = iceStarkData.store.get('commonAction')?.domainConfigs;
import * as validation from './validation';

import queryString from 'query-string';
import { TabList } from 'tyb';
import { TabModified } from 'ucode-components';



class CreateCodePrintingConfigStep3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  getTabList = (promotionType) => {
    switch (promotionType) {
      case 'QR_CODE':
      case 'INTERACT':
      case 'INVITE':
      case 'CHECK_IN':
      default:
        return [
          {
            text: '印刷类型',
            value: 0,
          }, {
            text: '信息配置',
            value: 1,
          }, {
            text: '审核流程',
            value: 2,
          }, {
            text: '预警参数',
            value: 3
          }
        ];
    }
  }

  handleOnChange = (val) => (values) => {
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    parsed.step = val;
    const path = location.pathname;
    history.replace(`${path}?${queryString.stringify(parsed)}`);

    this.props.setEditStep({ step: 3, values });
  }

  componentDidMount() {
    const { codePrintingConfigById: { genNoactiveWntime, regNoactiveWntime, downloadNoactiveWntime, ipcNoDownloadWntime=2 } } = this.props;

  if (this.props.editStep3) {
      this.props.initialize({ ipcNoDownloadWntime: 2, ...this.props.editStep3 })
    }
    else {
      this.props.initialize({ ipcNoDownloadWntime: 2, genNoactiveWntime, regNoactiveWntime, downloadNoactiveWntime, ipcNoDownloadWntime })

    }
  }


  perStep = () => {
    this.props.setCurrentStep(1);
  }

  next = (values) => {

    const { codePrintingConfigById, editStep1, editStep2 } = this.props;
    const params = {
      combinationTagType: 'SINGLE_TAG',
      ipcNoDownloadWntime:2,
      ...codePrintingConfigById,
      ...editStep1,
      ...editStep2,
      ...values,
      configWorkflowTemplateDTOs: editStep2 ? JSON.parse(JSON.stringify(editStep2.configWorkflowTemplateDTOs)) : codePrintingConfigById.wfTemplateList,

    }

    params.configWorkflowTemplateDTOs.forEach(v => {
      if (!v.id) {
        v.factoryId = v.factoryId.value
        v.workflowNodes.forEach(v2 => {
          v2.signerCode = v2.signerCode.value
        })
      }

      if (v.workflowNodes.length > 0) {
        let flag = false
        v.workflowNodes.map(it1 => {
          if ((it1.signerCode == -1 || it1.signerCode.value == -1) && it1.nodeType == 'PROCESS_START') {
            flag = true
          }
        })
        if (flag) {
          v.workflowNodes = []
        } else {
          if (v.workflowNodes[1].signerCode == '-1') {
            v.workflowNodes.splice(1, 1);
          }
        }
      }
    });
    params.multiCodeAsc == '-1' ? params.multiCodeAsc = false : '';
    this.props.editConfig({ params, history: this.props.history });
  }




  canCancel = () => {
    this.setState({
      confirmStatus: true
    });
  }

  cancel = () => {
    this.props.history.goBack();
  }

  requiredByInput = validation.required('请填写')

  max99999 = validation.max(99999);

  min1 = validation.min(1);
  min0 = validation.min(0);
  render() {
    const { handleSubmit, change, addressBookSelections, factorySelector, permissionIds, editStep1 } = this.props;
    const inputStyle = {
      width: 70,
      fontSize: 12,
      marginLeft: 10,
      marginRight: 10
    };


    const parsed = queryString.parse(this.props.location.search);
    const { step } = parsed;


    return (
      <div>
        <TabModified>
          <TabList
            list={this.getTabList('QR_CODE')}
            value={parseFloat(step)}
            onChange={(val) => handleSubmit(this.handleOnChange(val))()}
          />
          {/* onClick={handleSubmit(this.next) */}
        </TabModified>

        <div className="EditCodePrintingConfig">
          <div className="step3">
            <div style={{ color: '#000000' }}>设置预警参数</div>
            <table>
              <thead>
                <tr>
                  <th className="table-padding">预警事件</th>
                  <th>预警参数设置</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-padding">码生成后未激活</td>
                  <td>
                    <div>
                      <FormGroupField
                        label="码生成后"
                        name="genNoactiveWntime"
                        component={Input}
                        style={inputStyle}
                        validate={[this.requiredByInput, validation.isNumber, this.min1, this.max99999]}
                      />
                    </div>
                    <div>小时 未设置激活策略预警</div>
                  </td>
                </tr>
                <tr>
                  <td className="table-padding">登记后未激活</td>
                  <td>
                    <div>
                      <FormGroupField
                        label="码信息录入后"
                        name="regNoactiveWntime"
                        component={Input}
                        style={inputStyle}
                        validate={[this.requiredByInput, validation.isNumber, this.min1, this.max99999]}
                      />
                    </div>
                    <div>小时 未设置激活策略预警</div>
                  </td>
                </tr>
                <tr>
                  <td className="table-padding">发码后未下载</td>
                  <td>
                    <div>
                      <FormGroupField
                        label="码包生成发送后"
                        name="downloadNoactiveWntime"
                        component={Input}
                        style={inputStyle}
                        validate={[this.requiredByInput, validation.isNumber, this.min1, this.max99999]}
                      />
                    </div>
                    <div>小时 未下载码包策略预警</div>
                  </td>
                </tr>
                {editStep1 && editStep1.passwordComplexity == 'IPC_SECRECY' &&
                  <tr>
                    <td className="table-padding">解码设备离线</td>
                    <td>
                      <div>
                        <FormGroupField
                          label="码包生成发送后"
                          name="ipcNoDownloadWntime"
                          component={Input}
                          style={inputStyle}
                          validate={[this.requiredByInput, validation.isNumber, this.min1, this.max99999]}
                        />
                      </div>
                      <div>小时 未拉取码包预警</div>
                    </td>
                  </tr>}


              </tbody>
            </table>

            <div className="line" ></div>
            <div className="button-block" >
              <Button className="weak-button" onClick={this.canCancel} >取消</Button>
              {/* <Button className="weak-button button-margin" onClick={this.perStep} >上一步</Button>   */}
              {permissionIds.includes('code.tagConfig.edit') && <Button className="button-margin" onClick={handleSubmit(this.next)} >确定</Button>}
              {(this.state.confirmStatus) && <TablePopConfirm
                strongText={'印刷规格暂未完成，确认离开吗？'}
                text={'离开后编辑内容不做保存，请谨慎操作'}
                styles={{ marginTop: -165 }}   // height: 154,
                button={['确定', '取消']}
                onOk={this.cancel}
                onCancel={() => { this.setState({ confirmStatus: false }) }}
              />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let CreateCodePrintingConfigStep3Form = reduxForm({
  form: 'CreateCodePrintingConfigStep3Form', // a unique identifier for this form
  // enableReinitialize: true,
})(CreateCodePrintingConfigStep3)

CreateCodePrintingConfigStep3Form = connect(
  state => ({
    // initialValues: state.codePrintingConfig.createStep3,
    // createStep0: state.codePrintingConfig.createStep0,
    editStep1: state.codePrintingConfig.editStep1,
    editStep2: state.codePrintingConfig.editStep2,
    editStep3: state.codePrintingConfig.editStep3,

    codePrintingConfigById: state.codePrintingConfig.codePrintingConfigById,
    permissionIds: state.users.permissionIds || [],
  }),
  {
    setCurrentStep,
    editConfig: editConfig.REQUEST,
    setEditStep
  }
)(CreateCodePrintingConfigStep3Form)

CreateCodePrintingConfigStep3Form = withRouter(CreateCodePrintingConfigStep3Form);

export default CreateCodePrintingConfigStep3Form;