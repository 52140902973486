import React from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { RadioButtonGroup, RadioGroup, Button, Tip, Input, FormGroupList } from 'tyb';
import { TablePopConfirm } from 'ucode-components';
import { NewSelect  } from 'ucode-components';
import { FormGroupField } from 'tyb';
const { loadTemplateConfigsList, setCreateStep, setCurrentStep, loadSelect } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
import * as validation from './validation';



import queryString from 'query-string';
import { TabList } from 'tyb';
import { TabModified } from 'ucode-components';

let BATCHSFILE = 1;

class FileCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    setTimeout(() => {
      const { value } = this.props;
      let batchsfile = 1;
      if (value > 1) {
        batchsfile = BATCHSFILE = 2;
      }
      if (value) {
        this.setState({ batchsfile, batchs: value });
      }

    }, 1);
  }
  // 文件切换
  handleBatchsFileChange = (value) => {
    BATCHSFILE = value;
    this.setState({ batchs: value == 1 ? 1 : 2, batchsfile: value });
    this.props.onChange(value == 1 ? 1 : 2);
    if (value == 2) {
      setTimeout(() => {
        document.getElementById('batchInput').focus();
      }, 100);
    }
  }

  // 文件个数
  handleChange = (e) => {
    let value = e.toString().replace(/[^0-9]/ig, '');
    this.setState({ batchs: value });
    this.props.onChange(value);
  }

  render() {
    const { batchsfile, batchs } = this.state;
    return (
      <div className="file-package">
        <NewSelect
          style={{ width: 160, marginRight: 10 }}
          list={[
            {
              text: '单码',
              value: 1,
            },
            {
              text: '同级码',
              value: 2,
            }
          ]}
          disabled
          value={batchsfile}
          onChange={this.handleBatchsFileChange}
        />
        {batchsfile == 1 ?
          <Input
            {...this.props}
            id="batchInput"
            value={'1个'}
            disabled
          />
          :
          <NewSelect
            disabled
            list={[
              {
                text: '2个',
                value: 2,
              },
              {
                text: '3个',
                value: 3,
              }
            ]}
            {...this.props}
            style={{ width: 160 }}
            id="batchInput"
            value={batchs}
            onChange={this.handleChange}
          />
        }
      </div>
    )
  }
}



class CreateCodePrintingConfigStep0 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTagMat: '',
      selecttype: {
        name1: '',
        name2: '',
      },
      selecttype2: {
        name1: '',
        name2: '',
        name3: '',
      }
    }
  }

  getTabList = (promotionType) => {
    switch (promotionType) {
      case 'QR_CODE':
      case 'INTERACT':
      case 'INVITE':
      case 'CHECK_IN':
      default:
        return [
          {
            text: '印刷类型',
            value: 0,
          }, {
            text: '信息配置',
            value: 1,
          }, {
            text: '审核流程',
            value: 2,
          }, {
            text: '预警参数',
            value: 3
          }
        ];
    }
  }

  handleOnChange = (val) => {
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    parsed.step = val;
    const path = location.pathname;
    history.replace(`${path}?${queryString.stringify(parsed)}`);
  }


  componentDidMount() {
    const { codePrintingConfigById: { combinationTagType, combinationConfigDtos = [], tagMatId, templateConfigId, multiCodeAsc = false } } = this.props;








    if (this.props.zuhe) {
      this.props.loadSelect();

      if (combinationTagType == 'SAME_LEVEL_TAG') {
        this.props.initialize({
          combinationTagType,
          conditionKeys: combinationConfigDtos.length.toString(),
          name1: combinationConfigDtos[0] ? combinationConfigDtos[0].eseConfigId : "",
          name2: combinationConfigDtos[1] ? combinationConfigDtos[1].eseConfigId : "",
          name3: combinationConfigDtos[2] ? combinationConfigDtos[2].eseConfigId : "",
        })
      }
      else {
        this.props.initialize({
          combinationTagType,
          batchs: (combinationConfigDtos.length - 1).toString(),
          name2_1: combinationConfigDtos[0] ? combinationConfigDtos[0].eseConfigId : "",
          name2_2: combinationConfigDtos[1] ? combinationConfigDtos[1].eseConfigId : "",
          name2_3: combinationConfigDtos[2] ? combinationConfigDtos[2].eseConfigId : "",
          name2_4: combinationConfigDtos[3] ? combinationConfigDtos[3].eseConfigId : "",
          multiCodeAsc: multiCodeAsc ? multiCodeAsc : '-1',
          count: combinationConfigDtos[1] ? combinationConfigDtos[1].count : "",
        })
      }




    }
    else {
      this.props.loadTemplateConfigsList();

      this.props.initialize({ tagMatId, templateConfigId })

    }




  }

  perStep = () => {

  }

  next = (values) => {

    const tagMatId = values.tagMatId;
    const templateConfigsList = this.props.templateConfigsList;
    let templateConfigVersion = '';
    const filter = templateConfigsList.filter(v => v.id === tagMatId);
    if (filter && filter.length > 0) {
      templateConfigVersion = filter[0].version;
    }
    console.log(values)

    this.props.setCreateStep({ step: 0, values: { ...values, templateConfigVersion } });
    this.props.setCurrentStep(1);

  }

  canCancel = () => {
    this.setState({
      confirmStatus: true
    });
  }

  cancel = () => {
    this.props.history.goBack();
  }
  requiredByname1 = validation.required('请选择码规格')
  requiredByname2 = validation.required('请选择箱码规格')
  requiredByTagMatId = validation.required('请选择赋码材料')

  requiredByTemplateConfigId = validation.required('请选模板配置')

  getDescriptionFile = (templateConfigsObById, tagMatId, templateConfigId) => {
    if (templateConfigsObById && tagMatId !== '' && templateConfigId !== '') {
      const list = templateConfigsObById[tagMatId];
      if (!list) { return '' }
      let result = ''
      list.forEach(v => {
        if (v.id === templateConfigId) {
          result = v.descriptionFile;
        }
      });
      return result;
    }
    return '';
  }

  render() {
    const { formValues, initialValues, templateConfigsList, codePrintingConfigById, selectTemplate = [], templateConfigsById, templateConfigsObById, handleSubmit, change, zuhe } = this.props;
    const { selectedTagMat } = this.state;

    const values = formValues && formValues.values ? formValues.values : {}

    // const list = this.props.selector.filter(key => key.combinationTagType == 'SINGLE_TAG' && key.enterpriseDomain.codeSourceType == 'PLATFORM_STANDARD' || key.enterpriseDomain.codeSourceType == 'PLATFORM_SHORT_CODE').map((item) => { return { value: item.id, text: item.name, historyTagTemplateConfig: item.historyTagTemplateConfig } });
    const list = this.props.selector.filter(key => (key.combinationTagType == 'SINGLE_TAG' || key.enterpriseDomain.codeSourceType == 'PLATFORM_SHORT_CODE') && key.enterpriseDomain.codeSourceType != 'CODE_IMPORT').map((item) => { return { value: item.id, text: item.name, wwAppId: item.wwAppId ? item.wwAppId : "", historyTagTemplateConfig: item.historyTagTemplateConfig } });


    const parsed = queryString.parse(this.props.location.search);
    const { step } = parsed;

    return (
      <div>
        <TabModified>
          <TabList
            list={this.getTabList('QR_CODE')}
            value={parseFloat(step)}
            onChange={(val) => this.handleOnChange(val)}
          />
        </TabModified>

        <div className="EditCodePrintingConfig">
          {!zuhe ?
            <div className="step0">
              <FormGroupField
                name="tagMatId"
                component={RadioButtonGroup}
                list={selectTemplate.filter(key => key.value == values.tagMatId)}
                onChange={(value) => {
                  // this.setState({
                  //   selectedTagMat: value
                  // })
                  // change('templateConfigId', null);
                }}
                validate={[this.requiredByTagMatId]}
              />


              {(values.tagMatId !== '') &&
                <FormGroupField
                  name="templateConfigId"
                  component={RadioGroup}
                  list={(templateConfigsById[values.tagMatId] || []).filter(key => key.value == values.templateConfigId)}
                  validate={[this.requiredByTemplateConfigId]}

                />
              }

              {this.getDescriptionFile(templateConfigsObById, values.tagMatId, values.templateConfigId) &&
                <iframe className="pdf-block" src={this.getDescriptionFile(templateConfigsObById, values.tagMatId, values.templateConfigId)} />
              }
              <div className="line" ></div>
              <div className="button-block">
                <Button className="weak-button" onClick={this.cancel} >取消</Button>

                {/*  <Button className="button-margin" onClick={handleSubmit(this.next)} >下一步</Button> */}
                {(this.state.confirmStatus) && <TablePopConfirm
                  strongText={'印刷规格暂未完成，确认离开吗？'}
                  text={'离开后编辑内容不做保存，请谨慎操作'}
                  styles={{ marginTop: -165 }}   // height: 154,
                  button={['确定', '取消']}
                  onOk={this.cancel}
                  onCancel={() => { this.setState({ confirmStatus: false }) }}
                />}
              </div>
            </div>
            :
            <div className="step0">
              <Tip style={{ marginTop: 20 }} hasClose={false} type="info">
                {values.combinationTagType == 'SAME_LEVEL_TAG' ? '同级码为一个物体上多个二维码，同级关系二维码之间在生成码后自动建立对应关系，每个二维码有独立身份ID。' :
                  '层级码为一个物体的包装关系的二维码，层级关系二维码之间在生成码后自动建立对应关系，每个二维码有独立身份ID，上级一般为包装箱，下级一般为商品，具体请以实际场景为准。'}
              </Tip>
              <FormGroupField
                // disabled={isEdit}
                name="combinationTagType"
                // label="活动类型"
                list={codePrintingConfigById.combinationTagType == 'SAME_LEVEL_TAG' ? [
                  {
                    text: '同级码',
                    value: 'SAME_LEVEL_TAG',
                    disabled: true,
                  }]
                  :
                  [{
                    text: '层级码',
                    value: 'MULTI_LEVEL_TAG',
                    disabled: true,
                  }
                  ]}
                component={RadioButtonGroup}

              />
              {values.combinationTagType == 'SAME_LEVEL_TAG' ?
                <FormGroupList>
                  <FormGroupField
                    label='同级关系码数量设置'
                    name="conditionKeys"
                    component={RadioGroup}
                    type="text"
                    list={[
                      { text: '2个', value: '2', disabled: true, },
                      { text: '3个', value: '3', disabled: true, },
                    ]}
                  // validate={validateName2}
                  // placeholder="请输入"
                  />
                  <FormGroupField
                    name="name1"
                    label='第1个码规格'
                    placeholder="请选择"
                    component={NewSelect}
                    style={{ width: 330 }}
                    list={list}
                    onChange={(v) => {
                      this.setState({ selecttype: { ...this.state.selecttype, name1: list.filter(key => key.value == v)[0] } })
                      change('name2', '')
                      change('name3', '')
                    }}
                    disabled
                    validate={[this.requiredByname1]}
                  />
                  <FormGroupField
                    name="name2"
                    label='第2个码规格'
                    placeholder="请选择"
                    component={NewSelect}
                    style={{ width: 330 }}
                    list={list.map((item) => {
                      if (item.value == values.name1) {
                        return { ...item, disabled: true }
                      }
                      let a = this.state.selecttype.name1.historyTagTemplateConfig;
                      let b = item.historyTagTemplateConfig;
                      if (a && b) {
                        if (a.facedsData && b.facedsData) {
                          if (JSON.parse(a.facedsData)[0].level == JSON.parse(b.facedsData)[0].level) {
                            if (JSON.parse(a.facedsData)[0].position == 'A' && (JSON.parse(b.facedsData)[0].position == 'B' || JSON.parse(b.facedsData)[0].position == 'C')) {
                              return { ...item, disabled: false }
                            }
                            else if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {

                              return { ...item, disabled: false }
                            }
                            else {
                              return { ...item, disabled: true }
                            }
                          } else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      }
                      else {
                        return { ...item, disabled: true }
                      }
                    }
                    )}
                    validate={[this.requiredByname1]}
                    disabled
                    onChange={(v) => {
                      this.setState({ selecttype: { ...this.state.selecttype, name2: list.filter(key => key.value == v)[0] } })
                      change('name3', '')
                    }}
                  />
                  {values.conditionKeys == 2 ? "" : <FormGroupField
                    name="name3"
                    label='第3个码规格'
                    placeholder="请选择"
                    component={NewSelect}
                    style={{ width: 330 }}
                    list={list.map((item) => {
                      if (item.value == values.name1) {
                        return { ...item, disabled: true }
                      }
                      if (item.value == values.name2) {
                        return { ...item, disabled: true }
                      }

                      let a = this.state.selecttype.name2.historyTagTemplateConfig;
                      let b = item.historyTagTemplateConfig;
                      if (a && b) {
                        if (a.facedsData && b.facedsData) {
                          if (JSON.parse(a.facedsData)[0].level == JSON.parse(b.facedsData)[0].level) {
                            if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {
                              return { ...item, disabled: false }
                            }
                            else {
                              return { ...item, disabled: true }
                            }
                          } else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      }
                      else {
                        return { ...item, disabled: true }
                      }
                    }
                    )}
                    validate={[this.requiredByname1]}
                    disabled
                  />
                  }

                </FormGroupList>
                :
                <FormGroupList>
                  <FormGroupField
                    className='no-group-addon'
                    label='层级关系的二维码比例设置'
                    name="count"
                    component={Input}
                    type="text"
                    validate={[this.requiredByTagMatId]}
                    addonBefore={<span>1：</span>}
                    disabled
                  />
                  <FormGroupField
                    // className='no-group-addon'
                    label='码包文件中码排序规则'
                    name="multiCodeAsc"
                    component={NewSelect}
                    style={{ width: 330 }}
                    list={[
                      {
                        text: '箱码在前瓶码在后',
                        value: '-1',
                      },
                      {
                        text: '瓶码在前箱码在后',
                        value: true
                      }
                    ]}
                    disabled
                  />



                  <FormGroupField
                    name="name2_1"
                    label="箱码规格"
                    placeholder="请选择"
                    component={NewSelect}
                    style={{ width: 330 }}
                    list={list}
                    disabled
                    onChange={(v) => {
                      this.setState({ selecttype2: { ...this.state.selecttype2, name1: list.filter(key => key.value == v)[0] } })
                      change('name2_2', '')
                      change('name2_3', '')
                      change('name2_4', '')
                    }}
                    validate={[this.requiredByname2]}
                  />
                  <FormGroupField
                    name="batchs"
                    label="文件数设置"
                    component={FileCount}
                  // required
                  // validate={[requiredBatchs]}
                  // onChange={(v) => this.handleTagCountChange(v)}
                  />
                  <FormGroupField
                    name="name2_2"
                    label={values.batchs == 1 ? '瓶规格' : '第1个码规格'}
                    placeholder="请选择"
                    component={NewSelect}
                    style={{ width: 330 }}
                    list={list.map((item) => {
                      if (item.value == values.name2_1) {
                        return { ...item, disabled: true }
                      }
                      let a = this.state.selecttype2.name1.historyTagTemplateConfig;
                      let b = item.historyTagTemplateConfig;
                      if (a && b) {
                        if (a.facedsData && b.facedsData) {
                          if ((JSON.parse(a.facedsData)[0].level == 'THIRD_LAYER' && (JSON.parse(b.facedsData)[0].level == 'SECOND_LAYER' || JSON.parse(b.facedsData)[0].level == 'FIRST_LAYER'))
                            || (JSON.parse(a.facedsData)[0].level == 'SECOND_LAYER' && JSON.parse(b.facedsData)[0].level == 'FIRST_LAYER')) {

                            // if (JSON.parse(a.facedsData)[0].position == 'A' && (JSON.parse(b.facedsData)[0].position == 'B' || JSON.parse(b.facedsData)[0].position == 'C')) {
                            //   return { ...item, disabled: false }
                            // }
                            // else if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {

                            return { ...item, disabled: false }
                            // }
                            // else {
                            // return { ...item, disabled: true }
                            // }
                          } else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      }
                      else {
                        return { ...item, disabled: true }
                      }
                    }
                    )}
                    disabled
                    onChange={(v) => {
                      this.setState({ selecttype2: { ...this.state.selecttype2, name2: list.filter(key => key.value == v)[0] } })

                      change('name2_3', '')
                      change('name2_4', '')
                    }}
                    validate={[this.requiredByname1]}
                  />
                  {values.batchs >= 2 ? <FormGroupField
                    name="name2_3"
                    label='第2个码规格'
                    placeholder="请选择"
                    component={NewSelect}
                    style={{ width: 330 }}
                    list={list}
                    disabled
                    onChange={(v) => {
                      this.setState({ selecttype2: { ...this.state.selecttype2, name3: list.filter(key => key.value == v)[0] } })

                      change('name4', '')
                    }}
                    validate={[this.requiredByname1]}
                    list={list.map((item) => {
                      if (item.value == values.name2_1) {
                        return { ...item, disabled: true }
                      }
                      if (item.value == values.name2_2) {
                        return { ...item, disabled: true }
                      }
                      let a = this.state.selecttype2.name2.historyTagTemplateConfig;
                      let b = item.historyTagTemplateConfig;
                      if (a && b) {
                        if (a.facedsData && b.facedsData) {
                          if (JSON.parse(a.facedsData)[0].level == JSON.parse(b.facedsData)[0].level) {
                            if (JSON.parse(a.facedsData)[0].position == 'A' && (JSON.parse(b.facedsData)[0].position == 'B' || JSON.parse(b.facedsData)[0].position == 'C')) {
                              return { ...item, disabled: false }
                            }
                            else if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {

                              return { ...item, disabled: false }
                            }
                            else {
                              return { ...item, disabled: true }
                            }
                          } else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      }
                      else {
                        return { ...item, disabled: true }
                      }
                    }
                    )}


                  /> : ""}
                  {values.batchs >= 3 ? <FormGroupField
                    name="name2_4"
                    label='第3个码规格'
                    placeholder="请选择"
                    component={NewSelect}
                    style={{ width: 330 }}
                    disabled
                    list={list.map((item) => {
                      if (item.value == values.name2_1) {
                        return { ...item, disabled: true }
                      }
                      if (item.value == values.name2_2) {
                        return { ...item, disabled: true }
                      }
                      if (item.value == values.name2_3) {
                        return { ...item, disabled: true }
                      }

                      let a = this.state.selecttype2.name3.historyTagTemplateConfig;
                      let b = item.historyTagTemplateConfig;
                      if (a && b) {
                        if (a.facedsData && b.facedsData) {
                          if (JSON.parse(a.facedsData)[0].level == JSON.parse(b.facedsData)[0].level) {
                            if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {
                              return { ...item, disabled: false }
                            }
                            else {
                              return { ...item, disabled: true }
                            }
                          } else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      }
                      else {
                        return { ...item, disabled: true }
                      }
                    }
                    )}
                    validate={[this.requiredByname1]}
                  /> : ""
                  }

                </FormGroupList>
              }
              <div className="line" ></div>
              <div className="button-block">
                <Button className="weak-button" onClick={this.canCancel} >取消</Button>
                {/* <Button className="button-margin" onClick={handleSubmit(this.next)} >下一步</Button> */}
                {(this.state.confirmStatus) && <TablePopConfirm
                  strongText={'印刷规格暂未完成，确认离开吗？'}
                  text={'离开后编辑内容不做保存，请谨慎操作'}
                  styles={{ marginTop: -165 }}   // height: 154,
                  button={['确定', '取消']}
                  onOk={this.cancel}
                  onCancel={() => { this.setState({ confirmStatus: false }) }}
                />}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

let CreateCodePrintingConfigStep0Form = reduxForm({
  form: 'CreateCodePrintingConfigStep0Form', // a unique identifier for this form
  enableReinitialize: true,
})(CreateCodePrintingConfigStep0)

CreateCodePrintingConfigStep0Form = connect(
  state => ({
    // initialValues: state.codePrintingConfig.createStep0,
    templateConfigsList: state.codePrintingConfig.templateConfigsList,
    selectTemplate: state.codePrintingConfig.selectTemplate,
    templateConfigsById: state.codePrintingConfig.templateConfigsById,
    templateConfigsObById: state.codePrintingConfig.templateConfigsObById,
    formValues: state.form.CreateCodePrintingConfigStep0Form,

    codePrintingConfigById: state.codePrintingConfig.codePrintingConfigById,
    selector: state.codePrintingConfig.selector,
  }),
  {
    loadTemplateConfigsList: loadTemplateConfigsList.REQUEST,
    loadSelect: loadSelect.REQUEST,
    setCreateStep,
    setCurrentStep,
  }
)(CreateCodePrintingConfigStep0Form)

CreateCodePrintingConfigStep0Form = withRouter(CreateCodePrintingConfigStep0Form);

export default CreateCodePrintingConfigStep0Form;